.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.container {
display: flex;
flex-wrap: wrap
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.group{
  background-color: rgba(6, 52, 170, 0.5) !important;
  border-color: #0634aa !important;
}
.searchBtn{
 float:right;
 padding-bottom: 50px;
}

.bntListSearch{
  float: right;
  
}
.cluster {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(45deg) scale(var(--ggs,1));
  width: 16px;
  height: 16px;
  border-radius: 0 100% 100% 100%;
  box-shadow: inset 0 0 0 2px
}
.css-wgv8mj-MuiPaper-root-MuiDialog-paper {
  background-color: #fcfcfc;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
  margin: 32px;
  position: relative;
  overflow-y: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-height: calc(100% - 64px);
  max-width: 1200px;
}
.myMuiButton {
 
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox;
    display: inline-flex !important;
    -webkit-align-items: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    -webkit-justify-content: center !important;
    justify-content: center !important;
    position: relative !important;
    box-sizing: border-box !important;
    -webkit-tap-highlight-color: transparent !important;
    background-color: transparent !important;
    outline: 0 !important;
    border: 0 !important;
    margin: 0 !important;
    border-radius: 0 !important;
    padding: 0 !important;
    cursor: pointer !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    vertical-align: middle !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    -webkit-text-decoration: none !important;
    text-decoration: none !important;
    color: inherit !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    font-weight: 500 !important;
    font-size: 0.875rem !important;
    line-height: 1.75 !important;
    letter-spacing: 0.02857em !important;
    text-transform: uppercase !important;
    min-width: 64px !important;
    padding: 6px 16px !important;
    border-radius: 4px !important;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    color: #fff !important;
    background-color: #3c4b64 !important;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12) !important;
    margin-bottom: 16px;
}

.myMuiIconButton {
  padding: 7px !important;
  
  }
  select:hover {
    box-shadow: 0 0 2px #999; 
  }
